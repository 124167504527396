import Container from "@material-ui/core/Container"
import chunk from "lodash.chunk"
import React, { ChangeEvent, useEffect, useMemo } from "react"
import styled from "styled-components"
import { OverviewArray } from ".."
import useScrollToTop from "../../../../hooks/useScrollToTop"
import useSessionState from "../../../../hooks/useSessionState"
import { useNavStateContext } from "../../../wrapRoot"
import Pagination from "../pagination"
import Card from "./card"

interface IProps {
  data: OverviewArray
  sessionKey: string
}

const Wrapper = styled.div`
  @media (max-width: 850px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
  }
`

const List: React.FC<IProps> = ({ data, sessionKey }) => {
  const [page, setPage] = useSessionState(`thha-list-${sessionKey}`, 1)
  const scrollToTop = useScrollToTop()
  const lastNavState = useNavStateContext()

  useEffect(() => {
    if (lastNavState === "PUSH") {
      setPage(1)
    }
  }, [])

  const paginatedPosts: Array<OverviewArray> = useMemo(
    () => chunk(data, 6),
    [data]
  )

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    scrollToTop()
    setPage(value)
  }

  return (
    <Container maxWidth="md" disableGutters>
      <Pagination
        count={paginatedPosts.length}
        page={page}
        onChange={handleChange}
      />
      <Wrapper>
        {useMemo(
          () =>
            paginatedPosts[page - 1].map((node, i) => (
              <Card key={node?.id} node={node} flip={i % 2 === 0} />
            )),
          [page]
        )}
      </Wrapper>
      <Pagination
        count={paginatedPosts.length}
        page={page}
        onChange={handleChange}
      />
    </Container>
  )
}

export default List
