import MuiPaper, { PaperProps } from "@material-ui/core/Paper"
import Typography, { TypographyProps } from "@material-ui/core/Typography"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { OverviewDataFragment } from "../../../../__generated__/graphql-gatsby"

interface IProps {
  node: Maybe<OverviewDataFragment>
  flip?: boolean
}

const GatsbyLink = styled(Link)`
  display: block;
  width: 100%;
  text-decoration: none;
  margin: ${({ theme }) => theme.spacing(4)}px 0;
  color: ${({ theme }) => theme.palette.text.primary};
  transition: ${({ theme }) =>
    theme.transitions.create("color", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    })};

  :hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: 850px) {
    width: auto;

    margin: ${({ theme }) => theme.spacing(4)}px;
  }

  @media (max-width: 720px) {
    margin: ${({ theme }) => theme.spacing(2)}px;
  }
`
type PProps = PaperProps & {
  flip: boolean
}
const Paper = styled(({ flip, ...props }) => <MuiPaper {...props} />)<PProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ flip }) => (flip ? "row-reverse" : "row")};
  overflow: hidden;
  color: inherit;

  @media (max-width: 850px) {
    height: 100%;
    flex-direction: column;
    width: 320px;
  }

  @media (max-width: 800px) {
    width: 280px;
  }
`

const TextWrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(2)}px`};
  margin-top: 0;
  margin-bottom: 0;
  color: inherit;
  width: calc(100% - 280px);
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (min-width: 850px) {
    justify-content: space-between;
  }
  @media (max-width: 850px) {
    width: 100%;
  }
`

const Image = styled(GatsbyImage)`
  width: 280px;
  height: 280px;

  @media (max-width: 850px) {
    width: 320px;
    height: 320px;
  }

  @media (max-width: 800px) {
    width: 280px;
    height: 280px;
  }
`

const Text = styled(({ ...props }) => (
  <Typography {...props} component="div" />
))`
  color: inherit;
  @media (max-width: 800px) {
    font-size: 0.875rem;
  }
  @media (min-width: 850px) {
    height: 100px;
  }
`

type MProps = TypographyProps & {
  flip: boolean
}
const More = styled(({ flip, ...props }) => <Typography {...props} />)<MProps>`
  font-weight: 500;
  text-decoration: underline;
  text-align: ${({ flip }) => (flip ? `right` : `left`)};
  margin-top: ${({ theme }) => theme.spacing(4)}px;

  @media (max-width: 850px) {
    margin-top: auto;
  }
`

const Card: React.FC<IProps> = ({ node, flip }) => {
  let excerpt: string = ""
  let altText = ""
  let image: any

  if (
    node?.__typename === "WpActiviteit" ||
    node?.__typename === "WpErvaring" ||
    node?.__typename === "WpNieuws" ||
    node?.__typename === "WpVacature"
  ) {
    excerpt = node.excerpt ? node.excerpt : ""
    image =
      node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
    altText = node.featuredImage?.node?.altText
      ? node.featuredImage?.node?.altText
      : ""
  }

  return (
    <GatsbyLink to={String(node?.uri)}>
      <Paper key={node?.id} flip={Boolean(flip)}>
        {image && <Image image={image} alt={altText} />}
        <TextWrapper>
          <Typography variant="h3" component="div" color="primary">
            {node?.title}
          </Typography>
          <Text variant="body2" dangerouslySetInnerHTML={{ __html: excerpt }} />
          <More variant="body1" flip={Boolean(flip)}>
            Lees meer...
          </More>
        </TextWrapper>
      </Paper>
    </GatsbyLink>
  )
}

export default Card
